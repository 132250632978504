import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPEN_AI_KEY,
  dangerouslyAllowBrowser: true, // Enable browser support (use with caution)
});

// Create a new thread
const createThread = async () => {
  return await openai.beta.threads.create({});
};

// Add a message to a thread
const addMessageToThread = async (threadId: string, userMessage: string) => {
  return await openai.beta.threads.messages.create(threadId, {
    role: 'user',
    content: userMessage,
  });
};

// Run the assistant on a thread and poll until completion
const runAssistantAndPoll = async (threadId: string, assistantId: string, additionalInstructions?: string) => {
  return await openai.beta.threads.runs.createAndPoll(threadId, {
    assistant_id: assistantId,
    instructions: additionalInstructions,
  });
};

// Retrieve a specific run
const retrieveRun = async (threadId: string, runId: string) => {
  return await openai.beta.threads.runs.retrieve(threadId, runId);
};

// List all messages in a thread
const listMessages = async (threadId: string) => {
  return await openai.beta.threads.messages.list(threadId);
};

// List all runs in a thread
const listRuns = async (threadId: string) => {
  return await openai.beta.threads.runs.list(threadId);
};

// List all steps in a run
const listSteps = async (threadId: string, runId: string) => {
  try {
    const response = await openai.beta.threads.runs.steps.list(threadId, runId);
    return response;
  } catch (error) {
    console.error(`Error listing steps for thread ${threadId} and run ${runId}:`, error);
    throw error;
  }
};

export {
  openai,
  createThread,
  addMessageToThread,
  runAssistantAndPoll,
  retrieveRun,
  listMessages,
  listRuns,
  listSteps, // Ensure this is exported
};