import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams, Navigate } from 'react-router-dom';
import './App.css';
import AudioButton from './AudioButton';
import {
  createThread,
  addMessageToThread,
  runAssistantAndPoll,
  retrieveRun,
  listMessages,
  listSteps,
  listRuns,
} from './openaiClient';

export type Message = {
  user: string;
  text: string;
};

function AppWrapper() {
  return (
    <Router>
      <Routes>
        <Route path="/:path" element={<App />} />
        <Route path="/" element={<Navigate to="/fx" replace />} />
      </Routes>
    </Router>
  );
}

function App() {
  // State variables
  const [messages, setMessages] = useState<Message[]>([]);
  const [value, setValue] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [lastResponse, setLastResponse] = useState<string | null>(null);
  const [shouldFetchTTS, setShouldFetchTTS] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const endOfMessagesRef = useRef<null | HTMLDivElement>(null);

  const { path } = useParams<{ path?: string }>();
  const effectivePath = path || 'fx'; // Default to 'fx' if path is undefined

  let assistantId = '';

  if (effectivePath.toLowerCase() === 'fx') {
    assistantId = process.env.REACT_APP_ASSISTANT_ID_FX || '';
  } else if (effectivePath.toLowerCase() === 'cx') {
    assistantId = process.env.REACT_APP_ASSISTANT_ID_CX || '';
  }

  // State to store the thread ID
  const [threadId, setThreadId] = useState<string | null>(null);

  // Initialize a new thread when the app loads
  useEffect(() => {
    const initializeThread = async () => {
      try {
        const thread = await createThread();
        console.log(`Thread created with ID: ${thread.id}`);
        setThreadId(thread.id);
      } catch (error) {
        console.error('Error creating thread:', error);
      }
    };

    initializeThread();
  }, []);

  const checkExistingRun = async (threadId: string) => {
    let attempts = 0;
    const maxAttempts = 15;

    try {
      console.log(`Checking for existing runs on thread ${threadId} for assistant ${assistantId}.`);
      const runs = await listRuns(threadId);
      const existingRun = runs.data.find(
        (run) => run.assistant_id === assistantId && run.status === 'in_progress'
      );

      if (existingRun) {
        console.log(`Found an in-progress run with ID ${existingRun.id}.`);
        while (existingRun.status === 'in_progress' && attempts < maxAttempts) {
          attempts++;
          console.log(`Waiting for run to complete. Attempt ${attempts}/${maxAttempts}.`);
          await new Promise((resolve) => setTimeout(resolve, 1500));

          const retrievedRun = await retrieveRun(threadId, existingRun.id);
          existingRun.status = retrievedRun.status;
          console.log(`Retrieved run status: ${existingRun.status}`);
        }

        if (existingRun.status !== 'in_progress') {
          console.log('Run completed.');
          return existingRun;
        } else {
          throw new Error(`Run did not complete after ${maxAttempts} attempts.`);
        }
      } else {
        console.log('No existing run found.');
        return null;
      }
    } catch (error) {
      console.error(`Error in checkExistingRun: ${error}`);
      throw error;
    }
  };

  const pollRetrieveRunAndListSteps = async (threadId: string, runId: string) => {
    let runStatus: string | undefined;
    let stepsStatus: string | undefined;
    let attempts = 0;
    const maxAttempts = 15;

    try {
      while ((!runStatus || !stepsStatus) && attempts < maxAttempts) {
        console.log(`Attempt ${attempts + 1}: Retrieving run and steps...`);

        const run = await retrieveRun(threadId, runId);
        if (run && typeof run.status !== 'undefined') {
          runStatus = run.status;
          console.log(`Run status: ${runStatus}`);
        } else {
          console.error('No run status available');
          throw new Error('No run status available');
        }

        const stepsResponse = await listSteps(threadId, runId);
        if (stepsResponse && stepsResponse.data) {
          const steps = stepsResponse.data;
          console.log(`Retrieved ${steps.length} steps.`);
          if (steps.length > 0) {
            stepsStatus = steps[steps.length - 1].status;
            console.log(`Last step status: ${stepsStatus}`);
          }
        } else {
          console.error('No steps available');
          throw new Error('No steps available');
        }

        if (runStatus === 'completed' && stepsStatus === 'completed') {
          console.log('Run and steps completed successfully.');
          return;
        } else if (stepsStatus === 'failed' || runStatus === 'failed') {
          throw new Error('Run or steps failed.');
        }

        attempts++;
        await new Promise((resolve) => setTimeout(resolve, 1500));
      }

      if (attempts >= maxAttempts) {
        throw new Error(`pollRetrieveRunAndListSteps: Max attempts reached (${maxAttempts}).`);
      }
    } catch (error) {
      console.error(`Error in pollRetrieveRunAndListSteps: ${error}`);
      throw error;
    }
  };

  const getResponse = async (userInput: string) => {
    try {
      console.log(`Adding user's message to thread ID ${threadId}`);
      await addMessageToThread(threadId!, userInput);

      console.log(`Checking for an existing run in thread ID ${threadId}`);
      const existingRun = await checkExistingRun(threadId!);

      if (existingRun) {
        console.log(`Using existing run with ID: ${existingRun.id}`);
        const messagesResponse = await listMessages(threadId!);
        const botMessage = messagesResponse.data.find(
          (message) => message.role === 'assistant' && message.run_id === existingRun.id
        );
        if (botMessage) {
          const botResponse = extractTextFromMessageContent(botMessage.content);
          if (botResponse) {
            handleNewBotMessage(botResponse);
            return botResponse;
          }
        } else {
          console.warn('No bot response found in existing run.');
        }
      }

      console.log(`No existing run found. Running assistant on new thread ID ${threadId}`);
      const run = await runAssistantAndPoll(threadId!, assistantId);
      console.log(`Assistant run created with ID: ${run.id}`);

      await pollRetrieveRunAndListSteps(threadId!, run.id);

      console.log(`Polling for messages after initiating assistant run ID ${run.id}`);
      const messagesResponse = await listMessages(threadId!);
      const botMessage = messagesResponse.data.find(
        (message) => message.role === 'assistant' && message.run_id === run.id
      );

      if (botMessage) {
        const botResponse = extractTextFromMessageContent(botMessage.content);
        if (botResponse) {
          handleNewBotMessage(botResponse);
          return botResponse;
        }
      } else {
        throw new Error('No response from OpenAI after initiating new run.');
      }
    } catch (error) {
      console.error(`Error in getResponse: ${error}`);
      throw error;
    }
  };

  const extractTextFromMessageContent = (content: any[]): string | null => {
    for (const block of content) {
      if (block.type === 'text' && block.text) {
        return block.text.value;
      }
    }
    return null;
  };

  useEffect(() => {
    async function fetchResponse() {
      try {
        const input = messages[messages.length - 1].text;
        const response = await getResponse(input);
        setMessages((prevMessages) => [
          ...prevMessages,
          { user: 'Bot', text: response || "Sorry, I couldn't process your request." },
        ]);
        setLoading(false);
      } catch (error) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { user: 'Bot', text: "Sorry, I'm having trouble connecting to the service. Please try again." },
        ]);
        setLoading(false);
        console.error('Error:', error);
      }
    }

    if (messages.length > 0 && messages[messages.length - 1].user === 'User') {
      fetchResponse();
    }

    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, isLoading]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!value) return;

    const userMessage: Message = { user: 'User', text: value };
    setMessages((prevMessages) => [...prevMessages, userMessage]);

    setLoading(true);
    setValue('');
  };

  const handleNewBotMessage = (newMessage: string) => {
    setLastResponse(newMessage);
    setShouldFetchTTS(true);
  };

  const handleFetchDone = () => {
    setShouldFetchTTS(false);
  };

  return (
    <div className="App">
      <header>
        <h1>CSS IMPACT Reference Guide</h1>
      </header>
      <div className="chat-box">
        {messages.map((message, index) => (
          <div key={index} className={`chat-message ${message.user}`}>
            {message.text.split('\n').map((line, i) => (
              <React.Fragment key={i}>
                {line} <br />
              </React.Fragment>
            ))}
          </div>
        ))}
        <div ref={endOfMessagesRef} />
      </div>
      <form onSubmit={handleSubmit}>
        <div className="input-wrapper">
          <input
            ref={inputRef}
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className="input"
            required
          />
          {isLoading && (
            <div className="loading-message">
              <span></span>
              <span></span>
              <span></span>
            </div>
          )}
        </div>
        <button type="submit" disabled={isLoading}>
          Send
        </button>
      </form>
      {lastResponse && (
        <AudioButton
          lastResponse={lastResponse}
          shouldFetchTTS={shouldFetchTTS}
          onFetchDone={handleFetchDone}
        />
      )}
    </div>
  );
}

export default AppWrapper;